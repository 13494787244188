<template>
  <div id="privacy">
    <div class="container">
      <h2 v-if="!$route.query.lang" class="privacy-title">{{ $t('user.privacy_title') }}</h2>
      <div v-html="$t('user.privacy')"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Privacy',
  data() {
    return {
    }
  },
  mounted() {

  },
}
</script>
<style lang="scss">
#privacy {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 12px;

  .privacy-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .subtitle {
    font-weight: 600;
    text-indent: 0;
  }

  p {
    text-indent: 1em;
  }
}
</style>